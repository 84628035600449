/* The path depends on the current location of the scss file */
@import "../../../node_modules/@chatscope/chat-ui-kit-styles/themes/default/variables";

$color-white: #fff !default;
$color-black: #000 !default;

// Here you can override variables from the imported file above. Check its source for a list of available variables
// $default-bg-color: var(--chat-color-theme);
// $color-text: red;
// $conversation-color: pink;
// $color-primary: #000;
// $color-primary-light: #FFF;
// $color-primary-dark: #000;
// $color-primary-dark: #000;
// $chat-container-bg-color: var(--chat-color-theme);
// $conversation-color: rgba(var(--chat-color-theme), 0.1);
// $conversation-bg-color: rgba(var(--chat-color-theme), 0.1);

$message-separator-color: rgba(0, 0, 0, 0.87);
$message-separator-bg-color: transparent; // or color-white
$message-separator-content-color: rgba(0,0,0,.6); // NOT WORKING
$message-separator-content-bg-color: transparent; // NOT WORKING
$message-separator-line-color: rgba(0,0,0,.6); // NOT WORKING

$main-container-border: 0; //solid 1px var(--chat-color-theme);
$main-container-color: rgba(0, 0, 0, 0.87);
$main-container-bg-color: transparent; // or color-white

$chat-container-color: rgba(0, 0, 0, 0.87);
$chat-container-bg-color: transparent; // or color-white
$chat-container-message-input-color: rgba(0, 0, 0, 0.87);
$chat-container-message-input-bg-color: hsla(0,0%,56.5%,.9);
$chat-container-input-toolbox-color: rgba(0, 0, 0, 0.87);
$chat-container-input-toolbox-bg-color: hsla(0,0%,56.5%,.9);

$conversation-header-color: rgba(0, 0, 0, 0.87);
$conversation-header-bg-color: hsla(0, 0%, 56.5%, .9);
$conversation-header-user-name-color: rgba(0, 0, 0, 0.87);
$conversation-header-user-name-bg-color: transparent;
$conversation-header-info-color: rgba(0,0,0,.6);
$conversation-header-info-bg-color: transparent;
// $conversation-header-action-button-color: normal !default;
// $conversation-header-action-button-bg-color: transparent !default;
// $conversation-header-actions-color: $color-text !default;
// $conversation-header-actions-bg-color: transparent !default;

$conversation-header-border-bottom: solid 1px hsla(0,0%,56.5%,.9);

$message-list-color: rgba(0, 0, 0, 0.87);
$message-list-bg-color: hsla(0, 0%, 64.7%, .5);
$message-list-typing-indicator-bg-color: transparent;
$message-list-loading-more-bg-color: $color-white !default;

$typing-indicator-text-color: #474747;
$typing-indicator-text-bg-color: transparent;
$typing-indicator-indicator-bg-color: transparent;
$typing-indicator-dot-bg-color: #474747;

$chat-container-message-input-border-top: solid 1px hsla(0,0%,56.5%,.9);

$message-input-bg-color: hsla(0,0%,56.5%,.9); // or color-white
$message-input-content-editor-color: rgba(0, 0, 0, 0.87);
$message-input-content-editor-bg-color: $color-white;
$message-input-content-editor-wrapper-bg-color: $color-white;
$message-input-content-editor-container-bg-color: $color-white;
// $message-input-content-editor-disabled-color: $color-text-medium !default;
// $message-input-content-editor-disabled-bg-color: rgba($message-input-content-editor-bg-color, 0.38) !default;

// $message-incoming-color: rgba(0, 0, 0, 0.87);
// $message-incoming-bg-color: rgba(157,240,201,.75);

// $message-outgoing-color: rgba(0, 0, 0, 0.87);
// $message-outgoing-bg-color: rgba(157,240,201,.75);

$message-content-color: rgba(0, 0, 0, 0.87);
$message-content-bg-color: var(--chat-incoming-message-color);

$message-content-incoming-color: rgba(0, 0, 0, 0.87);
$message-content-incoming-bg-color: var(--chat-incoming-message-color);

$message-content-outgoing-color: rgba(0, 0, 0, 0.87);
$message-content-outgoing-bg-color: var(--chat-outgoing-message-color);

$button-color: #474747;
$button-bg-color: transparent;

$button-send-color: #474747;
$button-send-bg-color: transparent;

$color-primary: var(--chat-outgoing-message-color); // This is to color the sidebar (sidebar-left-bg-color and sidebar-right-bg-color are not working)
$color-primary-light: hsla(0, 0%, 64.7%, .5);

// $sidebar-right-bg-color: var(--chat-outgoing-message-color);
// $sidebar-left-bg-color: var(--chat-outgoing-message-color);